import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Typography } from '@mui/material';
import Filters from './filters';

import DataTable from '../../../../shared/components/data_table';
import { MODEL_DEF } from '../model_def';
import { makeStyles } from '@mui/styles';
import { STYLES } from '../../../../features/board/const/styles'
import CallToActionAlert from '../../../../shared/components/CallToActionAlert';

const useStyles = makeStyles((theme) => (STYLES(theme)));

const STYLES2 = {
  header: {
    marginBottom: "25px",
    marginTop: "25px",
  }
};

export default ({ firms, roles }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    _.find(MODEL_DEF.columns, {column: "roles"}).options = {firms, roles};
  }, [firms, roles]);

  const modelDefFilters = () => {
    return Object.assign(MODEL_DEF, { filters: filters })
  }

  return(
    <div>
      <CallToActionAlert />
      <Filters
        classes={classes}
        firms={firms}
        roles={roles}
        onChange={(data) => setFilters(data)}
      />
      <Typography variant="h5" style={ STYLES2.header }>
        Manage Users
      </Typography>
      <DataTable {...modelDefFilters()} />
      {/* <DataTable {{ ...MODEL_DEF, ... }} /> */}
    </div>
  );
};
