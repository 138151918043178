import React from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((_theme) => ({
  box: {
    marginBottom: "10px",
  },
}));

export default ({ task }) => {
  const classes = useStyles();
  const organization = task.organizational_structure || [];

  const level1 = () => {
    const firm = organization[0];
    if (!firm) {
      return null;
    }
    return levelDisplay(firm);
  };

  const level2 = () => {
    const account = organization[1];
    if (!account) {
      return null;
    }
    return levelDisplay(account);
  };

  const level3 = () => {
    const location = organization[2];
    if (!location) {
      return null;
    }
    return levelDisplay(location);
  };

  const levelDisplay = (level) => {
    return (
      <Chip
        avatar={<Avatar alt={level.name} src={level.avatar} />}
        label={level.name}
        variant="outlined"
        style={{ marginRight: "3px" }}
      />
    );
  };

  return (
    <div className={classes.box}>
      {level1()}
      {level2()}
      {level3()}
    </div>
  );
};
