import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { Button, Card, TextField } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const useStyles = makeStyles((_theme) => ({
  wrapper: {
    diplay: "flex",
    flexGrow: 1,
    marginTop: "10px",
  },
  elementRow: {
    display: "flex",
    flexGrow: 1,
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
  },
  cardWrapper: {
    padding: "10px",
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  const [message, setMessage] = useState("");

  const onAdd = (e) => {
    const confirm = window.confirm(
      "Sending this message will alert your SMTs. Are you sure you want to continue?"
    );

    if (!confirm) {
      return;
    }

    const commentValue = `#call_to_action ${message}`;
    onSubmit(commentValue);
    setMessage("");
  };

  const MoreButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropDown />}
      onClick={() => setShowMore(!showMore)}
    >
      Show Call To Action
    </Button>
  );

  const LessButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropUp />}
      onClick={() => setShowMore(!showMore)}
    >
      Hide Call To Action
    </Button>
  );

  const onLocalChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className={classes.wrapper}>
      {!showMore && <MoreButton />}
      {showMore && <LessButton />}
      {showMore && (
        <Card variant="outlined" className={classes.cardWrapper}>
          <div className={classes.elementRow}>
            <TextField
              label="Message"
              multiline
              size="small"
              type="text"
              className={classes.textField}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <Button
            variant="contained"
            color="warning"
            size="small"
            onClick={onAdd}
          >
            Add Call To Action
          </Button>
        </Card>
      )}
    </div>
  );
};
