import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Box, Grid, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TaskRowHeader from "../_mytask/_TaskRowHeader";
import MultiTimeTracker from "../_mytask/row/multi_time_tracker/Main";
import { useFirmTasks } from "../../../../../context/firm_tasks";

import AssignedTo from "./_AssignedTo";
import Organization from "./_Organization";
import ShortDescription from "./_ShortDescription";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    marginBottom: "12px",
    padding: "12px",
    backgroundColor: "#fffeee",
  },
  box: {
    marginBottom: "12px",
  },
  left: {
    width: "88%",
  },
  right: {
    width: "12%",
    paddingTop: "16px",
    minHeight: "203px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "normal",
    margin: "1px",
  },
  header: {
    width: "100%",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: "74px",
  },
  expand: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: "16px",
    marginBottom: "4px",
  },
}));

export default ({
  task,
  show_task_info,
  onToggleShowTaskInfo,
  onChange,
  preferences,
}) => {
  const classes = useStyles();
  const { fetchReferences } = useFirmTasks();
  const isAccountingTask = task?.accounting_project_task_id !== null;

  useEffect(() => {
    if (isAccountingTask) fetchReferences(task);
  }, [task]);

  return (
    <Card elevation={3} className={classes.card} key={`task-row-${task.id}`}>
      <Box className={classes.left}>
        <TaskRowHeader task={task} preferences={preferences} />
        <CardContent className={classes.content}>
          <Grid
            container
            justifyContent="space-between"
            direction="column"
            alignItems="flex-start"
          >
            <Organization task={task} />
            <AssignedTo task={task} />
            <ShortDescription task_description={task.description} />
          </Grid>
        </CardContent>
      </Box>
      <Box className={classes.right}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <MultiTimeTracker task={task} onChange={onChange} />
          <IconButton aria-label="settings" className={classes.expand}>
            <ExpandMoreIcon
              fontSize="large"
              onClick={() => onToggleShowTaskInfo(!show_task_info)}
            />
          </IconButton>
        </Grid>
      </Box>
    </Card>
  );
};
