import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Stack";
import linkifyHtml from "linkify-html";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
  },
  box: {
    display: "flex",
    flexGrow: 1,
    marginRight: "2px",
    minHeight: "100px",
    width: "80%",
  },
}));

export default ({ task_description }) => {
  const classes = useStyles();
  const characterLimit = 300;

  const formatMessage = (message) => {
    if (message) {
      // Truncate message if it's too long
      let formatted = message.replace(/(?:\r\n|\r|\n)/g, " --- ");
      if (formatted.length > characterLimit) {
        return formatted.substring(0, characterLimit) + "...";
      } else {
        return formatted;
      }
    } else {
      return message;
    }
  };

  const linkified = () => {
    if (task_description) {
      return linkifyHtml(formatMessage(task_description), { target: "_blank" });
    } else {
      return "";
    }
  };

  return (
    <Box className={classes.box}>
      <div className="ql-editor">
        <span dangerouslySetInnerHTML={{ __html: linkified() }} />
      </div>
    </Box>
  );
};
