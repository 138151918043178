import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import linkifyHtml from 'linkify-html';

const useStyles = makeStyles((_theme) => ({
  cell_large: {
    width: "300px",
  },
  cell: {
    width: "130px",
    textAlign: "center",
  },
}));

export default ({ results, date_range_str, showInitialMessage }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([]);

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  const taskLink = (task_link) => {
    if (!task_link) {
      return "";
    }

    return linkifyHtml(task_link, { target: '_blank' });
  }

  return (
    <>
      <Typography gutterBottom variant="h6" component="div">
        {date_range_str}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>Requester Name</TableCell>
              <TableCell className={classes.cell}>Approver Name</TableCell>
              <TableCell className={classes.cell}>Status</TableCell>
              <TableCell className={classes.cell}>Firm</TableCell>
              <TableCell className={classes.cell}>Account</TableCell>
              <TableCell className={classes.cell}>Locations</TableCell>
              <TableCell className={classes.cell}>Parent</TableCell>
              <TableCell className={classes.cell}>Child</TableCell>
              <TableCell className={classes.cell}>Approved At (MNL)</TableCell>
              <TableCell className={classes.cell}>Approved At (EST)</TableCell>
              <TableCell className={classes.cell}>Link to Task</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {local_results.length === 0 && (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  {showInitialMessage
                    ? "Please click 'Filter' or 'Download Report' to load the data."
                    : "No data found for the selected filters."}
                </TableCell>
              </TableRow>
            )}
            {local_results.map((result, _indx) => (
              <TableRow
                key={`tr_${result.indx}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {result.requester_name}
                </TableCell>
                <TableCell className={classes.cell}>{result.approver_name}</TableCell>
                <TableCell className={classes.cell}>{result.status}</TableCell>
                <TableCell className={classes.cell}>{result.firm}</TableCell>
                <TableCell className={classes.cell}>{result.account}</TableCell>
                <TableCell className={classes.cell}>
                  {result.locations}
                </TableCell>
                <TableCell className={classes.cell}>{result.parent}</TableCell>
                <TableCell className={classes.cell}>{result.child}</TableCell>
                <TableCell className={classes.cell}>
                  {result.approved_at_mnl}
                </TableCell>
                <TableCell className={classes.cell}>
                  {result.approved_at_est}
                </TableCell>
                <TableCell className={classes.cell}>
                  <span dangerouslySetInnerHTML={{ __html: taskLink(result.task_link) }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
