import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import TaskShow from "./_TaskShow";
import TaskComments from "../_mytask/_TaskComments";
import { doAuthenticatedGetPromise } from "../../../../../../actions/_methods";
import { API_TASKS_PREVIOUS_DATA } from "../../../../../../const/api_paths";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default ({ task, onHide, onTaskUpdate, onTaskDelete, preferences }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [previousComments, setPreviousComments] = useState([]);

  const previousTasks = task.ancestors;
  const hasPreviousTasks = previousTasks.length > 0;

  const apiEndpoint = (id) => {
    return API_TASKS_PREVIOUS_DATA.replace(":task_id", id);
  };

  const loadPreviousData = async (id) => {
    try {
      const response = await doAuthenticatedGetPromise(apiEndpoint(id));
      return response;
    } catch (error) {
      console.error("Error fetching data for id", id, error);
      return null;
    }
  };

  useEffect(() => {
    if (hasPreviousTasks) {
      Promise.all(previousTasks.map((id) => loadPreviousData(id)))
        .then((responses) => {
          const enrichedResponses = responses
            .map((response, index) => {
              if (response) {
                const taskId = previousTasks[index];

                const enrichedFiles =
                  response.files?.map((file) => ({
                    ...file,
                    task_id: taskId,
                  })) || [];

                const enrichedComments =
                  response.comments?.map((comment) => ({
                    ...comment,
                    task_id: taskId,
                    carry_over: true,
                  })) || [];

                return {
                  ...response,
                  files: enrichedFiles,
                  comments: enrichedComments,
                };
              }
              return null;
            })
            .filter((response) => response !== null);

          setData((existingData) => [...existingData, ...enrichedResponses]);
        })
        .catch((error) => {
          console.error("Error with fetching ancestral tasks", error);
        });
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      let allFiles = [];
      let allComments = [];

      data.forEach((item) => {
        if (item.files) {
          allFiles = [...allFiles, ...item.files];
        }
        if (item.comments) {
          allComments = [...allComments, ...item.comments];
        }
      });

      setPreviousFiles(allFiles);
      setPreviousComments(allComments);
    }
  }, [data]);

  return (
    <div className={classes.wrapper}>
      <TaskShow
        task={task}
        onTaskUpdate={onTaskUpdate}
        previousFiles={previousFiles}
        preferences={preferences}
      />

      <TaskComments
        task={task}
        onHide={onHide}
        previousComments={previousComments}
      />
    </div>
  );
};
