import React, { useEffect, useState } from "react";

import {
  doAuthenticatedPut,
  doAuthenticatedGet,
} from "../../../../../../actions/_methods";
import {
  API_MANAGE_FIRM_PREFERENCES,
  API_TASKS,
} from "../../../../../../const/api_paths";

import TaskRow from "./_TaskRow";
import TaskInfo from "./_TaskInfo";

export default ({ task, is_open = false }) => {
  const [local_task, setLocalTask] = useState(null);
  const [show_task_info, setShowTaskInfo] = useState(is_open);
  const [task_details, setTaskDetails] = useState(null);
  const [refreshing_task, setRefreshingTask] = useState(false);
  const [preferences, setPreferences] = useState({});

  useEffect(() => {
    setLocalTask(task);
    setTaskDetails(task);
  }, [task]);

  const onToggleShowTaskInfo = (is_show_task_info) => {
    if (is_show_task_info) {
      refreshTask();
    }
    setShowTaskInfo(is_show_task_info);
  };

  const refreshTask = () => {
    setRefreshingTask(true);
    doAuthenticatedGet(
      `${API_TASKS}/${task.id}`,
      {},
      {
        success: onRefreshTask,
        general: onRefreshTask,
        error: (res) => {
          alert("Please try again");
          setRefreshingTask(false);
        },
      }
    );
  };

  const onChange = (data) => {
    doAuthenticatedPut(`${API_TASKS}/${task.id}`, data, {
      success: (res) => onTaskUpdate(res.task),
      general: (res) => onTaskUpdate(res.task),
      error: (res) => alert("Please try again"),
    });
  };

  const onTaskUpdate = (updated_task) => {
    setLocalTask(updated_task);
    onHideTaskInfo();
  };

  const onTaskDelete = (deleted_task) => {
    setShowTaskInfo(false);
    setLocalTask(null);
  };

  const onHideTaskInfo = () => {
    setShowTaskInfo(false);
  };

  const onRefreshTask = ({ task }) => {
    setLocalTask(task);
    setTaskDetails(task);
    setRefreshingTask(false);
  };

  const reloadTask = () => {
    refreshTask();
  };

  const preferencesEndpoint = () => {
    return API_MANAGE_FIRM_PREFERENCES.replace(":firm_id", task.firm_id);
  };

  const loadPreferences = () => {
    doAuthenticatedGet(
      preferencesEndpoint(),
      {},
      {
        success: (res) => setPreferences(res.preferences),
        general: (res) => setPreferences(res.preferences),
        error: (res) => console.error(res),
      }
    );
  };

  useEffect(() => {
    loadPreferences();
  }, [task]);

  return (
    <>
      {local_task && (
        <>
          {!show_task_info && (
            <TaskRow
              task={local_task}
              show_task_info={show_task_info}
              onToggleShowTaskInfo={onToggleShowTaskInfo}
              onChange={onChange}
              preferences={preferences}
            />
          )}
          {show_task_info && !refreshing_task && (
            <TaskInfo
              task={task_details}
              onHide={onHideTaskInfo}
              onTaskDelete={onTaskDelete}
              onTaskUpdate={onTaskUpdate}
              preferences={preferences}
            />
          )}
          {show_task_info && refreshing_task && <div>loading...</div>}
          <a id={`task_reloader_${task.id}`} onClick={reloadTask}></a>
        </>
      )}
    </>
  );
};
