import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '180px',
    marginRight: '2px',
    minHeight: '155px',
    // overflow: 'scroll',
  },
  working_wrapper: {
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center'
  },
  working_icon: {
    color: '#1b97fc', 
    marginRight: '4px', 
    width: '15px'
  },
  working_name: {
    fontSize: '11px', 
    fontStyle: 'italic'
  }
}));

export default ({task, onChange}) => {
  const classes = useStyles();

  const ongoingWorkers = () => {
    return _.filter(task.status_per_user, {status: 'on_going'})
  }

  return (
    <Box className={classes.box} >
      <Status task={task} field={`status`} update_field={`pseudo_status`} onChange={onChange} />
      {ongoingWorkers().map(w => {
        return(
          <div className={classes.working_wrapper} key={`ongoing_work_${task.id}_${w.user_id}`}>
            <RunCircleIcon fontSize="small" className={classes.working_icon} />
            <small className={classes.working_name}>{w.display_name}</small>
          </div>
        )
      })}
    </Box>
  )
}
