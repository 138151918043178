import React from 'react';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import AddNewLocation from './_add_location';
import ActionLocation from './_actions_location';

const useStyles = makeStyles((theme) => ({}));

export default ({ account }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.cell}>
              <AddNewLocation account={account} />
            </TableCell>
          </TableRow>
          {account.locations.map((location) => (
            <TableRow key={`location_${location.id}`}>
              <TableCell
                component="th"
                scope="row"
                className={classes.cell}
                style={{ verticalAlign: 'top' }}
              >
                <Stack direction="row" spacing={2}>
                  <Avatar alt={location.name} src={location.avatar} />
                  <b>{location.name}</b>
                </Stack>
                <ActionLocation location={location} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
