import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { doAuthenticatedGet } from "../../actions/_methods";
import { API_TASKS_CALL_TO_ACTIONS } from "../../const/api_paths";

const useStyles = makeStyles((_theme) => ({
  alertContainer: {
    marginBottom: "2rem",
    fontWeight: "800",
    fontsize: "1.5rem",
  },
}));

const CallToActionAlert = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [ctaTasks, setCtaTasks] = useState([]);

  useEffect(() => {
    setLoading(true);
    doAuthenticatedGet(
      API_TASKS_CALL_TO_ACTIONS,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => console.error(res),
      }
    );
  }, []);

  const onSuccess = (res) => {
    setCtaTasks(res.tasks);
    setLoading(false);
  };

  if (loading) {
    return null;
  }

  return (
    <Box className={classes.alertContainer}>
      {ctaTasks.length > 0 && (
        <Alert
          severity="warning"
          sx={{ fontWeight: "bold", fontSize: "1rem" }}
          action={
            <Link href="/app/my_tasks">
              <Button color="success">View My Tasks</Button>
            </Link>
          }
        >
          You have {ctaTasks.length} items that need your attention!
        </Alert>
      )}
    </Box>
  );
};

export default CallToActionAlert;
