import { API_MANAGE_TASK_REQUESTS } from "../../../const/api_paths";

export const MODEL_DEF = {
  api_path: API_MANAGE_TASK_REQUESTS,
  model_name: "task_request",
  peer_name: "task_requests",
  columns: [
    { column: "name", header: "Task Name", type: "string" },
    { column: "requester", header: "Requester", type: "string" },
    { column: "created_at", header: "Requested At", type: "date" },
    { column: "due_date", header: "Due Date", type: "date" },
    { column: "firm_name", header: "Firm", type: "string" },
    { column: "task_category_name", header: "Sub-Category", type: "string" },
    { column: "parent_category_name", header: "Category", type: "string" },
  ],
  listable_columns: [
    { column: "name", header: "Task Name" },
    { column: "requester", header: "Requester" },
    { column: "created_at", header: "Requested At (UTC)" },
    { column: "due_date", header: "Due Date" },
    { column: "firm_name", header: "Firm" },
    { column: "parent_category_name", header: "Category" },
    { column: "task_category_name", header: "Sub-Category" },
  ],
};
