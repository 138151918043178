import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import {
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  CardHeader,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";

import { TASK_REQUEST_STATUS_OPTIONS } from "../../board/const/ref";
import { doAuthenticatedGet } from "../../../actions/_methods";
import { API_PATH, TASK_REQUEST_REPORTS } from "../../../const/api_paths";
import InputPeople from "../../board/components/_basic_filters/InputPeople";
import DateRange from "../../board/components/_basic_filters/DateRange";
import InputSelect from "../../board/components/_basic_filters/InputSelect";
import { useReferences } from "../../context/references";

import Organizational from "../user_data/components/_Organizational";
import TaskCategory from "../user_data/components/_TaskCategory";
import Results from "./components/_Results";

const useStyles = makeStyles((_theme) => ({
  card: {
    marginTop: "10px",
    border: "1px solid #9f9f9f",
  },
  actionButtons: {
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
}));

export default () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    connected_users: user_options,
    organizations,
    loadConnectedUsers,
    loadOrganizations,
    loadTaskCategories,
  } = useReferences();

  const calendarDefault = {
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  };
  const defaultFilters = {
    start_date: moment().startOf("week").format("YYYY-MM-DD"),
    end_date: moment().endOf("week").format("YYYY-MM-DD"),
  };
  const [results, setResults] = useState([]);
  const [date_range_str, setDateRangeStr] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [disableGenerateReport, setDisableGenerateReport] = useState(false);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    loadConnectedUsers();
    loadOrganizations();
    loadTaskCategories();

    // Load the initial data
    setFilters({ ...filters, timezone: tz });
    // handleFilter(); Save bandwidth by not loading the first time
  }, []);

  const doFilter = (page) => {
    setLoading(true);
    setShowInitialMessage(false);
    var target_url = `${TASK_REQUEST_REPORTS}?page=${page}&${dataToParams()}`;
    doAuthenticatedGet(
      target_url,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const handleFilter = () => {
    setPage(1);
    doFilter(1);
  };

  const onSuccess = (res) => {
    setDisableGenerateReport(false);
    setLoading(false);
    setResults(res["rows"]);
    setDateRangeStr(res["date_range_readable"]);
    setTotalPages(res["total_pages"]);
  };

  const onError = (res) => {
    let errorMessage = "Unable to fetch the report:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
    setDisableGenerateReport(true);
    setLoading(false);
  };

  const onPaginate = (event, value) => {
    setPage(value);
    doFilter(value);
  };

  const handleGenerateReport = () => {
    console.log("Generate Report", filters);

    const target_url = API_PATH(
      `/reports/task_requests.csv?page=${page}&${dataToParams()}`
    );
    console.log(target_url);
    window.location = target_url;
  };

  const dataToParams = () => {
    const new_data = { ...filters };
    Object.keys(new_data).forEach((key) => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  };

  const handleChange = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeOrganization = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeTaskCategory = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeDateRange = (selection) => {
    var newFilters = {
      ...filters,
      ...{
        start_date: moment(selection.startDate).format("YYYY-MM-DD"),
        end_date: moment(selection.endDate).format("YYYY-MM-DD"),
      },
    };
    setFilters(newFilters);
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="Task Requests" />
      <CardContent>
        <Box>
          <Stack direction="row" spacing={2}>
            <InputPeople
              name="requester_id"
              title="Requester"
              options={user_options}
              onChange={handleChange}
            />
            <InputPeople
              name="approver_id"
              title="Approver"
              options={user_options}
              onChange={handleChange}
            />
            <Box display="inline-flex" justifyContent="space-between">
              <Organizational
                onChange={onChangeOrganization}
                options={organizations}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TaskCategory onChange={onChangeTaskCategory} />
            <InputSelect
              width={230}
              name="status"
              title="Status"
              options={TASK_REQUEST_STATUS_OPTIONS}
              onChange={handleChange}
              default_value={""}
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <Typography variant="h7">Date Created/Approved/Rejected:</Typography>
            <DateRange
              start_date={calendarDefault.startDate}
              end_date={calendarDefault.endDate}
              onChange={onChangeDateRange}
            />
          </Stack>
          <Stack direction="row" my={2}>
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              className={classes.actionButtons}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
                disabled={disableGenerateReport}
              >
                Download Report
              </Button>
            </Stack>
          </Stack>
        </Box>
        {loading && <Stack mt={2}>Loading... </Stack>}
        {!loading && (
          <Results
            results={results}
            date_range_str={date_range_str}
            showInitialMessage={showInitialMessage}
          />
        )}
        <Stack mt={2}>
          <Pagination
            count={total_pages}
            page={page}
            onChange={onPaginate}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => {
              return <PaginationItem {...item} />;
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
