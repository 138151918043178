import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Avatar, TextField, FormControl } from "@mui/material";

const useStyles = makeStyles({
  control: {
    width: 260,
  },
  listbox: {
    maxHeight: 200,
    overflow: "auto",
  },
  label: {
    color: "#00000029",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      width: 28,
      height: 28,
      marginRight: 10,
    },
  },
  optionStyle: {
    fontSize: "11pt",
    padding: 10,
    cursor: "pointer",
    borderBottom: "1px solid #00000029",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
});

const defaultStyles = {
  width: 260,
};

const optionStyles = {
  display: "flex",
  fontSize: "11pt",
  padding: "10px",
  cursor: "pointer",
  borderBottom: "1px solid #555",
  marginBottom: "0",
};

const DropdownUsers = ({
  users,
  styles,
  label,
  setSelectedUser,
  controlClassName,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedUser(newValue);
  };

  const OptionComponent = ({ option, props }) => {
    const avatar = option.profile_pic ? (
      <Avatar src={option.profile_pic}>{option.code}</Avatar>
    ) : (
      <Avatar>{option.code}</Avatar>
    );
    return (
      <div style={optionStyles} {...props}>
        <span className={classes.avatarWrapper}>{avatar}</span>
        {option.label}
      </div>
    );
  };

  return (
    <FormControl className={controlClassName || classes.control}>
      <Autocomplete
        size="small"
        value={value}
        onChange={handleChange}
        options={users}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || "Select a user"}
            variant="outlined"
            InputLabelProps={{ className: classes.label }}
          />
        )}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        sx={styles || defaultStyles}
      />
    </FormControl>
  );
};

export default DropdownUsers;
