import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, TextField, Button } from "@mui/material";

import AddCommentCustom from "./AddCommentCustom";
import AddCommentNightlyReport from "./AddCommentNightlyReport";
import AddCallToAction from "./AddCallToAction";
import { useFirmTasks } from "../../../../../../context/firm_tasks";

import { API_TASK_COMMENTS } from "../../../../../../../const/api_paths";
import { doAuthenticatedPost } from "../../../../../../../actions/_methods";

const useStyles = makeStyles((theme) => ({
  box: {
    flexGrow: 1,
    width: "100%",
    marginTop: "10px",
    marginRight: "2px",
  },
  input: {
    width: "100%",
    marginRight: "10px",
  },
  input_wrapper: {
    display: "flex",
    flexGrow: 1,
  },
  sub_actions: {
    diplay: "flex",
  },
}));

const title = "Comment";

export default ({ task, onSubmit }) => {
  const classes = useStyles();
  const { preferences } = useFirmTasks();
  const [value, setValue] = useState();
  const [submitting, setSubmitting] = useState(false);
  const showNightlyReport = preferences.nightly_report === "true";
  const showAddCallToAction = task.task_category_parent_name !== "Call To Action";

  const apiEndpoint = () => {
    return API_TASK_COMMENTS.replace(":task_id", task.id);
  };

  const doAddComment = (comment_value, override_id = null, user_id = null) => {
    setSubmitting(true);
    doAuthenticatedPost(
      apiEndpoint(),
      { comment: comment_value, override_by: override_id, user_id: user_id },
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const onSuccess = (res) => {
    onSubmit(res);
    setValue("");
    setSubmitting(false);
  };

  const onError = (res) => {
    alert("Please try again");
    setSubmitting(false);
  };

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
  };

  const onSubmitLocal = () => {
    if (hasWorkonComment()) {
      alert("Please use the in behalf of feature.");
    } else {
      doAddComment(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmitLocal();
    }
  };

  const hasWorkonComment = () => {
    const pattern = /^([\+\-])(\d+)\s*work/;
    return pattern.test(value);
  };

  return (
    <Box className={classes.box}>
      <div className={classes.input_wrapper}>
        <TextField
          variant="outlined"
          size="small"
          className={classes.input}
          label={title}
          value={value}
          onChange={onChangeLocal}
          disabled={submitting}
          onKeyPress={handleKeyPress}
        />
        <Button variant="contained" onClick={onSubmitLocal}>
          Add
        </Button>
      </div>

      {showAddCallToAction &&
        <AddCallToAction
          onSubmit={(comment_value) => doAddComment(comment_value)}
        />
      }

      <AddCommentCustom
        onSubmit={(comment_value, override_id, user_id) =>
          doAddComment(comment_value, override_id, user_id)
        }
      />
      {showNightlyReport && (
        <AddCommentNightlyReport
          onSubmit={(comment_value) => doAddComment(comment_value)}
        />
      )}
    </Box>
  );
};
