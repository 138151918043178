import React, { useState, useEffect } from "react";
import TaskInfo from './components/_tasks/variants/_task/_TaskInfo';
import LoadingTasks from './components/_tasks/LoadingTasks';
import { useGlobal } from '../context/global';
import { TaskCtxProvider } from "../context/task";
import { doAuthenticatedPut, doAuthenticatedGet } from "../../actions/_methods";
import { API_TASK, API_TASK_SHOW } from '../../const/api_paths';

import { makeStyles } from '@mui/styles';
import { STYLES } from './const/styles'
const useStyles = makeStyles((theme) => (STYLES(theme)));

export default ({task_id}) => {
  const classes = useStyles();
  const { setMyself, setFirmUsers } = useGlobal();
  // const [group_by, setGroupBy] = useState('firm');
  // const [filters, setFilters] = useState({firm_id: parseInt(firm_id)});
  const [task, setTask] = useState();
  const [local_task, setLocalTask] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    doAuthenticatedGet(API_TASK_SHOW.replace(':task_id', task_id), {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    setMyself(res['myself']);
    setTask(res['task']);
    setLocalTask(res['task']);
    setFirmUsers(res['assigned_users']);
    setLoading(false);
  }

  const onError = (res) => {
    console.log(res)
    alert('Unable to fetch the tasks, please try again.');
    setLoading(false);
  }

  const onChange = (data) => {
    doAuthenticatedPut(API_TASK.replace(':task_id', task_id), data, {
      success: (res) => onTaskUpdate(res.task),
      general: (res) => onTaskUpdate(res.task),
      error: (res) => alert("Please try again"),
    });
  };

  const onTaskUpdate = (updated_task) => {
    setLocalTask(updated_task);
  };

  return(
    <div>
      {loading ? (
        <LoadingTasks loading={loading} />
      ) : (
        <TaskCtxProvider>
          <TaskInfo
            task={local_task}
            onTaskUpdate={onChange}
            onUpdateComment={loadData}
          />
        </TaskCtxProvider>
      )}
    </div>
  )

}
