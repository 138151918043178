import React, { useState, useEffect } from 'react';
import LoadingTasks from './components/_tasks/LoadingTasks';
import PreviewTask from './components/PreviewTask';
import MyTasks from './components/_tasks/variants/MyTasks';
import CallToAction from './components/_tasks/variants/CallToAction';

import { API_MY_TASKS } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

import { useGlobal } from '../context/global';

export default ({ firm_id }) => {
  const { setMyself, setFirmUsers, setPageKnowledgeBaseTags } = useGlobal();
  const [tasks, setTasks] = useState([]);
  const [ctaTasks, setCtaTasks] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const group_by = 'firm';
  const filters = {firm_id: parseInt(firm_id)};

  useEffect(() => {
    loadData();
    setPageKnowledgeBaseTags(["My Tasks"]);
  }, []);

  const loadData = () => {
    setLoading(true);
    doAuthenticatedGet(
      API_MY_TASKS,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const onSuccess = (res) => {
    const references = res['references'];

    setOrganizations(res['organizations']);
    setTasks(res['tasks']);
    setCtaTasks(res['cta_tasks']);
    setMyself(res['myself']);
    setFirmUsers(references.assigned_users);
    setLoading(false);
  };

  const onError = (res) => {
    console.log(res);
    alert('Unable to fetch the tasks, please try again.');
    setLoading(false);
  };

  return (
    <div>
      <PreviewTask />
      <LoadingTasks loading={loading} />
      <CallToAction tasks={ctaTasks} />
      <MyTasks
        tasks={tasks}
        organizations={organizations}
        group_by={group_by}
        filters={filters}
      />
    </div>
  );
};
