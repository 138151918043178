import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardContent } from "@mui/material";

import Category from "../_mytask/show/_Category";
import Tags from "../_mytask/show/_Tags";
import WorkAsOneLocations from "../_mytask/show/_WorkAsOneLocations";
import Status from "../_mytask/show/_Status";
import Files from "../_mytask/show/files/_Files";
import Rules from "../_mytask/show/_Rules";
import Description from "../_mytask/show/_Description";
import CheckList from "../_mytask/show/_Checklist";
import TaskRowHeader from "../_mytask/_TaskRowHeader";
import AssignedTo from "./_AssignedTo";
import Organization from "./_Organization";

const useStyles = makeStyles((_theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    width: "65%",
    marginBottom: "20px",
  },
  inner_box: {
    display: "flex",
    marginTop: "10px",
  },
  inner_box_spaced: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
}));

export default ({ task, onTaskUpdate, previousFiles, preferences }) => {
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    setData(task);
  }, [task]);

  return (
    <Card className={classes.box}>
      <TaskRowHeader task={task} preferences={preferences} />
      <CardContent style={{ paddingTop: "0px" }}>
        <Box>
          <Category task={task} />
        </Box>
        <Box className={classes.inner_box_spaced}>
          <Status task={task} />
        </Box>
        <Box className={classes.inner_box_spaced}>
          <Organization task={task} />
        </Box>
        <Box
          className={classes.inner_box_spaced}
          style={{ marginBottom: "20px" }}
        >
          <AssignedTo task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Tags task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <WorkAsOneLocations task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <CheckList task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Description task_description={task.description} />
        </Box>
        <Box className={classes.inner_box}>
          <Rules task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Files files={task.files} previousFiles={previousFiles} />
        </Box>
      </CardContent>
    </Card>
  );
};
