import React from "react";
import { Typography } from "@mui/material";

import TaskWrapper from "../../../components/_tasks/variants/_cta/_TaskWrapper";
import { TaskCtxProvider } from "../../../../context/task";

export default ({ tasks }) => {
  return (
    <>
      {tasks.length > 0 && (
        <Typography variant="h6" gutterBottom>
          Items that need your attention:
        </Typography>
      )}
      {tasks.map((task) => (
        <TaskCtxProvider>
          <TaskWrapper key={`task-wrapper-${task.id}`} task={task} />
        </TaskCtxProvider>
      ))}
    </>
  );
};
