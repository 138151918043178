import React from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";

export default ({ task }) => {
  const taskRequest = task.task_request;
  const approver = taskRequest?.approver;

  if (!taskRequest) {
    return null;
  }

  if (!approver) {
    return null;
  }

  return (
    <div>
      <Typography variant="h8">Approved by:</Typography>
      <Chip
        avatar={<Avatar alt={approver.label} src={approver.avatar} />}
        label={approver.label}
        variant="outlined"
        style={{ marginLeft: "3px" }}
      />
      <Typography variant="h8" sx={{ paddingLeft: "10px" }}>
        Task requested on:{" "}
      </Typography>
      {taskRequest.requested_at}
    </div>
  );
};
